import request from '@/api/request.js'
export function exportData(data) {
  return request({
    url: "/api/report/excel/exportData",
    method: "post",
    headers: {
      "Content-Type": 'multipart/form-data'
    },
    data: data
  });
}

export function downloadExportData(data) {
  return request({
    url: "/api/report/excel/exportData",
    method: "post",
    responseType: 'blob',
    headers: {
      "Content-Type": 'multipart/form-data'
    },
    data: data
  });
}